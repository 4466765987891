.strength-container {
  position: relative;
}
.strength-container .form-control {
  background: transparent;
  position: relative;
  z-index: 10;
}
.strength-container .strength-meter {
  margin: 1px 0;
  z-index: 5;
  height: 4px;
  background-color: #ffffff;
  background-image: none;
}
.strength-container .strength-meter div {
  width: 0%;
  height: 100%;
  line-height: 100%;
  border: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.strength-container .strength-very-weak {
  background-color: #d9534f;
  width: 25% !important;
}
.strength-container .strength-weak {
  background-color: #f0ad4e;
  width: 50% !important;
}
.strength-container .strength-medium {
  background-color: #5bc0de;
  width: 75% !important;
}
.strength-container .strength-strong {
  background-color: #5cb85c;
  width: 100% !important;
}
