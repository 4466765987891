.text-muted {
  color: #808080;
}
h1,
h2,
h3,
h4 {
  font-weight: 300;
}
.break-all {
  word-break: break-all;
}
.no-break {
  white-space: nowrap;
}
.wrap-all {
  word-wrap: break-word;
}
.toggable {
  cursor: pointer;
}
label,
.lead,
.panel-title {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 200;
}
