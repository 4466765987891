textarea {
  resize: vertical;
}
input:focus,
.form-control,
.form-control:focus {
  box-shadow: none;
}
input:focus {
  box-shadow: none;
}
.sms-control {
  border-radius: 0px 0px 0 0;
  margin-bottom: 0;
}
.sms-control:focus {
  border-color: #bfbfbf;
  box-shadow: none;
}
.form-group {
  margin-bottom: 5px;
}
select.form-control {
  -webkit-appearance: none;
  border-radius: 0px;
}
.picker__input[disabled],
.picker__input[readonly],
fieldset[disabled] .picker__input {
  cursor: pointer;
  background: #ffffff;
  opacity: 1;
}
.password-rules {
  margin: 0px 0 10px;
  padding: 0px 12px;
  font-size: 12px;
  color: #808080;
}
.password-rules .extra-content {
  display: none;
  padding: 10px 0px;
}
.password-rules .extra-content ul {
  list-style-position: outside;
  padding: 0;
  padding-left: 15px;
}
.password-rules .extra-content ul li {
  margin-bottom: 5px;
}
.password-rules.show .extra-content {
  display: block;
}
