.table td.expand-cell,
.table th.expand-cell {
  width: 100%;
}
.table td.minimize-cell,
.table th.minimize-cell {
  width: 1px;
}
.table td.ellipsis-cell,
.table th.ellipsis-cell {
  position: relative;
}
.table td.ellipsis-cell .ellipsis-container,
.table th.ellipsis-cell .ellipsis-container {
  display: block;
  position: absolute;
  overflow: hidden;
  left: 5px;
  right: 5px;
}
.table td.ellipsis-cell .ellipsis-container span,
.table th.ellipsis-cell .ellipsis-container span {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.table.table-nomargin {
  margin: 0;
}
.table.table-clean thead th {
  border-bottom: 1px solid #f2f2f2;
}
.table.table-clean thead .label-row th {
  font-size: 12px;
  font-weight: 300;
  color: #808080;
}
.table.table-clean tbody td {
  border-top: 0;
}
