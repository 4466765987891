.text-dot {
  display: inline-block;
  vertical-align: middle;
  height: 5px;
  width: 5px;
  margin: 0 3px;
  border-radius: 50%;
  background-color: #262626;
}
.text-dot.text-dot-sm {
  height: 3px;
  width: 3px;
}
.text-dot.text-dot-lg {
  height: 8px;
  width: 8px;
}
.text-dot.text-primary {
  background-color: #f09b16;
}
.text-dot.text-success {
  background-color: #5cb85c;
}
.text-dot.text-info {
  background-color: #5bc0de;
}
.text-dot.text-warning {
  background-color: #f0ad4e;
}
.text-dot.text-danger {
  background-color: #d9534f;
}
.ellipsis-box {
  position: relative;
}
.ellipsis-box .ellipsis-container {
  display: block;
  position: absolute;
  overflow: hidden;
  left: 5px;
  right: 5px;
}
.ellipsis-box .ellipsis-container span {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
