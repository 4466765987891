.modal {
  z-index: 12000;
}
.modal-backdrop {
  z-index: 11000;
}
.modal-content {
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
