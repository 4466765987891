.thumbnail-card {
  background-color: #f2f2f2;
  text-align: center;
  color: #000;
  text-decoration: none;
}
.thumbnail-card:hover {
  text-decoration: none;
}
.thumbnail-card .lead {
  margin-bottom: 0;
  letter-spacing: normal;
}
