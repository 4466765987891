.onoffswitch {
  position: relative;
  width: 90px;
  user-select: none;
  transition: all 0.2s ease-in-out;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 0px;
}
.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.2s ease-out 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "ON";
  padding-left: 10px;
  background-color: #d9534f;
  color: #FFFFFF;
}
.onoffswitch-primary .onoffswitch-inner:before {
  content: "ON";
  padding-left: 10px;
  background-color: #f09b16;
  color: #FFFFFF;
}
.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 10px;
  background-color: #FFFFFF;
  color: #262626;
  text-align: right;
}
.onoffswitch-switch {
  display: block;
  width: 18px;
  margin: 6px;
  background: #f2f2f2;
  border: 1px solid #808080;
  border-radius: 0px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 56px;
  transition: all 0.2s ease-out 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
