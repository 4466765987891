#header {
  color: #000;
  z-index: 11000;
}
#header a[href] {
  color: #000;
  transition: all 0.1s ease-out;
  box-shadow: inset 0 0 0 transparent;
}
#header a[href].noIndicator {
  box-shadow: none !important;
}
#header .nav {
  padding-bottom: 15px;
}
#header .nav > li a {
  padding: 8px 16px 8px 8px;
  font-weight: 300;
}
#header .nav > li a:hover {
  text-decoration: none;
  background-color: transparent;
  box-shadow: inset -5px 0 0 #bfbfbf;
}
#header .nav > li a:focus {
  text-decoration: none;
  background-color: #f2f2f2;
  box-shadow: inset -5px 0 0 #f09b16;
}
#header .nav > li.active a {
  text-decoration: none;
  background-color: #f2f2f2;
  box-shadow: inset -5px 0 0 #f09b16;
}
.header-brand {
  padding: 25px 0;
  text-align: center;
}
img.logo {
  width: 75%;
  max-width: 200px;
  margin-top: 8px;
}
.header-title {
  margin-top: 20px;
  padding: 0 8px;
  font-size: 14px;
  text-transform: uppercase;
}
.user-box {
  margin: 0 0 0 10px;
  padding: 0 0 0 5px;
}
.wallets-box {
  padding-left: 8px;
}
.wallets-box a {
  padding-left: 0px;
}
.user-box {
  margin: 0 10px;
  padding: 0 5px;
  border-top: 1px solid #f09b16;
}
.user-box .header-label {
  padding: 0;
}
#header .nav-footer {
  padding-bottom: 0px;
  width: 100%;
}
#header .nav-footer .nav > li a {
  padding: 8px 16px;
}
#header .nav.nav-wallets > li a {
  padding: 8px 16px 8px 8px;
}
.header-label {
  margin: 10px 0 5px;
  padding: 0 8px;
  font-family: 'Helvetica Neue', Arial, Sans-serif;
  font-weight: 200;
  letter-spacing: 0.8px;
  font-size: 12px;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  #header {
    width: 50px;
    padding: 0;
    margin: 0;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;
    border: 0;
    background-color: #f2f2f2;
    box-shadow: inset -15px 0 15px -15px #ebebeb;
    height: 100%;
  }
  #header .wallet-balance {
    font-size: 20px;
  }
  #header header {
    height: 100%;
  }
  #header header .header-nav {
    min-height: 100%;
    transition: margin 0.3s ease-out;
  }
}
#header .nav #dropdown-content {
  padding-left: 20px;
  list-style: none;
  display: none;
  padding-left: 0;
}
#header .nav #dropdown-content a {
  display: block;
  padding: 3px 0 3px 20px;
  text-decoration: none;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  #header .nav > li > a {
    padding: 8px 0;
    font-size: 20px;
    text-align: center;
  }
  #header .nav > li > a > span {
    display: none;
  }
  #header .nav #dropdown-content span {
    display: none;
  }
  #header .nav #dropdown-content {
    margin-left: -25px;
  }
  #header .nav #dropdown-content a {
    padding-left: 40px;
  }
  #header .nav #dropdown-content a i {
    opacity: .8;
  }
}
@media screen and (min-width: 992px) {
  #header {
    width: 200px;
  }
  #header .nav [class^="icon-"],
  #header .nav [class*=" icon-"] {
    margin-right: 2px;
  }
  #header .header-brand {
    bottom: 15px;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  #header {
    width: 100%;
    border-right: 0;
    border-bottom: 2px solid #f09b16;
  }
}
