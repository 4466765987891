.panel-title {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 0px;
}
.panel-actions {
  float: right;
  margin-right: -10px;
}
.panel-actions i {
  color: #9c9c9c;
  text-align: center;
  width: 30px;
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  text-decoration: none;
}
.panel-actions i:hover {
  color: #000000;
}
.panel-default > .panel-heading {
  color: #262626;
}
.panel-nav .nav-form {
  position: relative;
  margin: 7.5px;
}
.panel-nav .nav-form i {
  color: #bfbfbf;
}
.panel-nav .nav-form .form-control {
  padding-right: 45px;
}
.panel-nav .nav-form .form-actions {
  position: absolute;
  right: 7.5px;
  top: 6.6px;
}
.panel-nav .badge-toggle:hover .badge-actions {
  display: inline;
}
.panel-nav .badge-toggle:hover .badge-count {
  display: none;
}
.panel-nav .badge {
  float: right;
}
.panel-nav .badge .badge-actions {
  display: none;
}
.panel-nav .throbber {
  float: right;
}
.panel-progress-container {
  position: relative;
  margin-bottom: 22px;
}
.panel-progress {
  position: absolute;
  right: -15px;
  left: -15px;
  border-radius: 0;
}
