.list-group-condensed .list-group-item {
  padding-top: 5px;
  padding-bottom: 5px;
}
.list-group-arrow .list-group-item {
  padding-right: 40px;
}
.list-group-arrow .list-group-item:after {
  content: '\f054';
  font-family: FontAwesome;
  position: absolute;
  right: 15px;
  top: 28%;
}
.list-group-arrow .list-group-item.list-group-disabled {
  color: #999;
  pointer-events: none !important;
  cursor: default;
}
.list-group-arrow .list-group-item.list-group-disabled .btn {
  pointer-events: auto !important;
  cursor: pointer;
}
.list-group-arrow .list-group-item.list-group-disabled:after {
  content: '';
}
