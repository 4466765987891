.progress {
  height: 4px;
  margin-bottom: 5px;
  box-shadow: none;
}
.progress-inline-block {
  font-size: 0;
  white-space: nowrap;
}
.progress-inline-block .progress-bar {
  float: none;
  display: inline-block;
}
.sms-progress {
  height: 3px;
  border-radius: 0 0 0px 0px;
  margin-bottom: 9px;
}
