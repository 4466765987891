.body-drag-visible {
  display: none;
}
body.bodyDragOver .body-drag-visible {
  display: block;
}
body.bodyDragOver .body-drag-hidden {
  display: none;
}
.drag-visible {
  display: none;
}
body.bodyDragOver .dragOver .drag-visible {
  display: block;
}
body.bodyDragOver .dragOver .drag-hidden {
  display: none;
}
.js-upload-dropzone.dropzone-target,
.js-upload-dropzone .dropzone-target {
  box-shadow: inset 0 0 0 0 transparent;
  transition: all 0.1s ease-out;
}
body.bodyDragOver .js-upload-dropzone.dropzone-target,
body.bodyDragOver .js-upload-dropzone .dropzone-target {
  box-shadow: inset 0 0 0 5px #bfbfbf;
}
body.bodyDragOver .js-upload-dropzone.dragOver.dropzone-target,
body.bodyDragOver .js-upload-dropzone.dragOver .dropzone-target {
  box-shadow: inset 0 0 0 5px #f09b16;
}
